import { useEffect, useState } from 'react';
import { Alert } from '@material-ui/lab';
import parse from 'html-react-parser';
import {
  Button, Grid, Typography, ListItem, ListItemIcon, ListItemText, makeStyles,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router';
import i18n from '../common/i18n';
import { useCustomerAccountStatus } from '../contexts/CustomerAccountStatusContext';
import { moneyFormatterWithoutDecimals, dateFormatter } from '../utils/formatterUtil';
import { getPathRouteFromKey } from '../utils/menuAndRoutesUtil';
import {
  fillMessageWith, sendDataLayerEvent,
} from '../utils/functionsUtil';
import RevolvingLineProgressBar from '../components/RevolvingLineProgressBar';
import creditLineRecharge from '../assets/imgs/credit-line-recharge.png';
import creditLineQR from '../assets/imgs/credit-line-qr.svg';
import imgArrowRight from '../assets/imgs/arrow-right-small.svg';
import RevolvingLineDetailLoansInstallments from '../components/common/RevolvingLineDetailLoansInstallments';
import { getCustomerRevolvingLine } from '../utils/loginUtil';
import { useAuth } from '../contexts/AuthContext';
import { saveCustomerAccountStatus } from '../api/userStorage';
import Loading from '../components/common/Loading';
import useQueryParam from '../customHook/useQueryParam';

const useStyles = makeStyles((theme) => ({
  parentDiv: {
    display: 'grid',
  },
  divHeaderTitle: {
    gridArea: '1 / 1 / 3 / 5',
    backgroundColor: theme.palette.primary.main,
    paddingTop: '25px',
    paddingBottom: '100px',
  },
  divHeaderAmounts: {
    gridArea: '2 / 2 / 4 / 4',
    marginLeft: '5%',
    marginRight: '5%',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '10px',
    borderRadius: '12px',
    border: '1px groove #e0e0e0',
    backgroundColor: 'white',
  },
  divBody: {
    gridArea: '4 / 2 / 5 / 4',
    marginLeft: '5%',
    marginRight: '5%',
    paddingBottom: '100px',
  },
  headerTitle: {
    fontWeight: '700',
    fontSize: '24px',
    fontFamily: 'Poppins',
    color: 'white',
  },
  listBulletUsed: {
    color: '#e72064',
    fontSize: '20px',
  },
  listBulletMax: {
    color: '#29CC6A',
    fontSize: '20px',
  },
  listText: {
    color: '#565656',
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: 'Open Sans',
  },
  listAmount: {
    color: '#565656',
    fontSize: '14px',
    fontWeight: '600',
    fontFamily: 'Poppins',
  },
  textArrowCTA: {
    fontFamily: 'Open Sans',
    fontWeight: '700',
    fontSize: '14px',
    color: '#e72064',
    textAlign: 'center',
  },
  textRegularizeAccount: {
    fontWeight: '400',
    fontSize: '14px',
    color: '#424242',
  },
  tabSelected: {
    fontWeight: '700',
    fontSize: '14px',
    color: '#e72064',
    cursor: 'pointer',
    borderBottom: '2px groove #e72064',
    padding: '10px',
  },
  tabNotSelected: {
    fontWeight: '400',
    fontSize: '14px',
    color: '#424242',
    cursor: 'pointer',
    borderBottom: '2px groove #424242',
    padding: '10px',
  },
  separator: {
    borderRadius: '1px',
    border: '1px solid #e0e0e0',
    width: '100%',
  },
  separatorCTA: {
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '2px',
    paddingRight: '2px',
  },
  buttonCTA: {
    borderRadius: '4px',
    border: '1.6px solid #e0e0e0',
    padding: '10px',
    width: '100%',
    height: '100%',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#e72064',
    },
    fontFamily: 'Open Sans',
    fontWeight: '400',
    fontSize: '14px',
    color: '#000000',
    textAlign: 'center',
  },
  card: {
    border: '1px groove ',
    borderRadius: '12px',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '15px',
    paddingRight: '20px',
  },
  nextDueDateTitle: {
    textAlign: 'left',
    fontFamily: 'Open Sans',
    fontWeight: '600',
    fontSize: '18px',
  },
  installmentAmount: {
    textAlign: 'left',
    fontWeight: '600',
    fontSize: '16px',
    fontFamily: 'Poppins',
    color: '#212121',
  },
  installmentDueDate: {
    textAlign: 'left',
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: 'Open Sans',
    color: '#212121',
  },
  lastLoansTitle: {
    textAlign: 'left',
    fontFamily: 'Poppins',
    fontWeight: '700',
    fontSize: '18px',
  },
}));

const RevolvingLineDetail = () => {
  const history = useHistory();
  const classes = useStyles();
  const { currentUser } = useAuth();
  const { customerAccountStatus, setCustomerAccountStatus } = useCustomerAccountStatus();
  const [loading, setLoading] = useState(true);
  const [customerRevolvingLine, setCustomerRevolvingLine] = useState();
  const queryParams = useQueryParam();
  const [showFinishedLoans, setShowFinishedLoans] = useState(queryParams.get('showFinishedLoans') === 'true');

  const goToPay = () => {
    history.push(getPathRouteFromKey(i18n.payInstallmentKey));
  };

  const goToPaymentQR = () => {
    history.push(getPathRouteFromKey(i18n.paymentQRKey));
  };

  const onNewCharge = () => {
    sendDataLayerEvent({
      event: 'lc_nueva_carga',
    });
    history.push(getPathRouteFromKey(i18n.revolvingLine));
  };

  const goToRevolvingLineDetailInstallments = () => {
    history.push(getPathRouteFromKey(i18n.revolvingLineDetailInstallments));
  };

  const goToRevolvingLineDetailLoans = () => {
    history.push({
      pathname: getPathRouteFromKey(i18n.revolvingLineDetailLoans),
      search: `showFinishedLoans=${showFinishedLoans}`,
    });
  };

  const init = async () => {
    try {
      setLoading(true);
      const revolvingData = await getCustomerRevolvingLine(currentUser.customerTaxId);
      if (revolvingData.campaignOffer) {
        history.push(getPathRouteFromKey(i18n.revolvingLineOffer));
      }
      revolvingData.movementsLoans = revolvingData.recharges ? [...revolvingData.recharges] : [];
      revolvingData.finishedLoans = revolvingData.finishedList ? [...revolvingData.finishedList] : [];
      customerAccountStatus.customerRevolvingLine = revolvingData;
      setCustomerRevolvingLine(revolvingData);
      saveCustomerAccountStatus(customerAccountStatus);
      setCustomerAccountStatus(customerAccountStatus);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const setMovementsLoans = () => {
    setShowFinishedLoans(false);
  };

  const setFinishedLoans = () => {
    setShowFinishedLoans(true);
  };

  useEffect(() => { init(); }, []);

  return (
    <>
      {loading && <Loading />}
      {customerRevolvingLine && !loading && (
      <>
        <div className={classes.parentDiv}>

          <div className={classes.divHeaderTitle}>
            <Typography className={classes.headerTitle}>
              {i18n.RevolvingLine.Header.title}
            </Typography>
          </div>

          <div
            className={classes.divHeaderAmounts}
            style={{ filter: customerAccountStatus.customerIsUpToDate === false ? 'grayscale(100%)' : 'grayscale(0%)' }}
          >
            <Grid container>

              {/* progress bar */}
              <Grid item xs={8}>
                <RevolvingLineProgressBar
                  maxAmount={customerRevolvingLine.maxAmount}
                  remainingAmount={customerRevolvingLine.remainingAmount}
                />
              </Grid>

              {/* amounts */}
              <Grid item xs={4}>
                <Box textAlign="left">
                  <ul style={{ paddingLeft: '25px' }}>
                    <li className={classes.listBulletUsed}>
                      <Typography className={classes.listText}>
                        {i18n.RevolvingLine.Detail.Header.usedAmount}
                      </Typography>
                      <Typography className={classes.listAmount}>
                        {moneyFormatterWithoutDecimals(customerRevolvingLine.maxAmount
                    - customerRevolvingLine.remainingAmount)}
                      </Typography>
                    </li>
                    <li className={classes.listBulletMax}>
                      <Typography className={classes.listText}>
                        {i18n.RevolvingLine.Detail.Header.maxAmount}
                      </Typography>
                      <Typography className={classes.listAmount}>
                        {moneyFormatterWithoutDecimals(customerRevolvingLine.maxAmount)}
                      </Typography>
                    </li>
                  </ul>
                </Box>
              </Grid>

              {/* linea horizontal */}
              <Grid item xs={12} style={{ padding: '2px' }}>
                <hr className={classes.separator} />
              </Grid>

              {/* CTA */}

              <Grid
                item
                xs={12}
              >
                <Grid container>
                  {customerAccountStatus.customerHasPrepaidCard && (
                    <Grid
                      item
                      xs={6}
                      className={classes.separatorCTA}
                    >
                      <Button
                        variant="text"
                        className={classes.buttonCTA}
                        onClick={onNewCharge}
                        disabled={customerAccountStatus.customerIsUpToDate === false}
                        startIcon={<img src={creditLineRecharge} width="24px" alt="Cargar tarjeta" />}
                      >
                        {i18n.RevolvingLine.Detail.newChargeCTA}
                      </Button>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={customerAccountStatus.customerHasPrepaidCard ? 6 : 12}
                    className={classes.separatorCTA}
                  >
                    <Button
                      variant="text"
                      className={classes.buttonCTA}
                      onClick={goToPaymentQR}
                      disabled={customerAccountStatus.customerIsUpToDate === false}
                      startIcon={<img src={creditLineQR} width="24px" alt="Pagar QR" />}
                    >
                      {i18n.RevolvingLine.Detail.paymentQRCTA}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>

          <div className={classes.divBody}>
            <Grid container>
              {customerAccountStatus.customerIsUpToDate === false && (
                <>
                  <Grid item xs={12} style={{ marginTop: '20px' }}>
                    <Alert severity="info" style={{ border: '1px solid #3988FF' }}>
                      <Typography align="left" className={classes.textRegularizeAccount}>
                        {i18n.RevolvingLine.Detail.regularizeAcctStatus}
                      </Typography>
                    </Alert>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: 'initial', marginTop: '20px' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={goToPay}
                      fullWidth
                    >
                      {i18n.customerDebtorLabelPayButton}
                    </Button>
                  </Grid>
                </>
              )}
              {customerRevolvingLine.nextExpirationInfo && (
                <>
                  <Grid item xs={12} style={{ marginTop: '20px' }}>
                    <Typography className={classes.nextDueDateTitle}>
                      {i18n.RevolvingLine.Detail.nextDueDateTitle}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: '10px', marginBottom: '25px' }}>
                    <Grid container className={classes.card}>
                      <Grid item xs={7}>
                        <Typography className={classes.installmentAmount}>
                          {parse(fillMessageWith(i18n.RevolvingLine.Detail.installmentAmount,
                            moneyFormatterWithoutDecimals(
                              customerRevolvingLine.nextExpirationInfo.amount,
                            )))}
                        </Typography>
                        <Typography className={classes.installmentDueDate}>
                          {fillMessageWith(i18n.RevolvingLine.Detail.installmentDueDate,
                            dateFormatter(customerRevolvingLine.nextExpirationInfo.vto))}
                        </Typography>
                      </Grid>
                      <Grid item xs={5} style={{ textAlign: 'right' }}>
                        <Button
                          variant="tertiary"
                          onClick={() => goToRevolvingLineDetailInstallments()}
                          style={{ padding: '0px', height: '100%' }}
                        >
                          <ListItem style={{ padding: '0px' }}>
                            <ListItemText
                              primary={(
                                <Typography className={classes.textArrowCTA}>
                                  {i18n.RevolvingLine.Detail.showDetailInstallments}
                                </Typography>
                              )}
                            />
                            <ListItemIcon style={{ minWidth: '24px' }}>
                              <img src={imgArrowRight} alt="back" width="20px" />
                            </ListItemIcon>
                          </ListItem>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Box
                      className={showFinishedLoans ? classes.tabNotSelected : classes.tabSelected}
                      onClick={setMovementsLoans}
                    >
                      {i18n.RevolvingLine.Detail.movementsLoansTabTitle}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      className={showFinishedLoans ? classes.tabSelected : classes.tabNotSelected}
                      onClick={setFinishedLoans}
                    >
                      {i18n.RevolvingLine.Detail.finishedLoansTabTitle}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ paddingTop: '20px' }}>
                <Grid container>
                  <Grid item xs={8}>
                    <Typography className={classes.lastLoansTitle}>
                      {showFinishedLoans
                        ? i18n.RevolvingLine.Detail.finishedLoansTitle : i18n.RevolvingLine.Detail.lastloansTitle}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="tertiary"
                      className={classes.textArrowCTA}
                      onClick={() => goToRevolvingLineDetailLoans()}
                      disabled={
                          !((!showFinishedLoans && customerRevolvingLine.movementsLoans.length > 2)
                          || (showFinishedLoans && customerRevolvingLine.finishedLoans.length > 2))
                        }
                    >
                      {i18n.RevolvingLine.Detail.loansShowAll}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                {/* last 3 loans */}
                <RevolvingLineDetailLoansInstallments
                  loanInstallments={
                    showFinishedLoans
                      ? [...customerRevolvingLine.finishedLoans].reverse().slice(0, 3)
                      : [...customerRevolvingLine.movementsLoans].reverse().slice(0, 3)
                    }
                />
              </Grid>
            </Grid>
          </div>

        </div>
      </>
      )}
    </>
  );
};

export default RevolvingLineDetail;
